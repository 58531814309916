import React, { Component } from "react";
import AppContext from "../../appContext";
import { Uploader } from "../../components/utils/upload/upload";
import "./index.css";
import Swal from "sweetalert2";
import { FaCirclePlus } from "react-icons/fa6";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaRegCircleCheck } from "react-icons/fa6";

class CreateFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: [],
      pgvalue: "",
      fileIndex: 0,
      perf: "-",
      status: "",
    };
    CreateFile.contextType = AppContext;
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handlePartSizeChange = this.handlePartSizeChange.bind(this);
    this.handleNumUploadsChange = this.handleNumUploadsChange.bind(this);
    this.handleTransferAccelerationChange = this.handleTransferAccelerationChange.bind(this);
    this.executeUpload = this.executeUpload.bind(this);
  }

  executeUpload() {
    const { file } = this.state;
    const totalFile = file.length ? file.length : 1;
    const uploaderOptions = {
      file: this.state.file,
      folder: this.props.folderSelect,
    };

    let percentage = undefined;
    this.setState({ pgvalue: 0, perf: "-" });

    const uploader = new Uploader({
      ...uploaderOptions,
    });
    uploader
      .onProgress(({ percentage: newPercentage, fileIndex: index, pgvalue: newpgvalue, status: status }) => {
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ pgvalue: percentage, fileIndex: index });
        }
        if (newpgvalue == "") {
          this.setState({ pgvalue: "" });
        }
        if (status == "end") {
          Swal.fire({
            html: `Upload concluído: ${totalFile} arquivo${totalFile !== 1 ? "s" : ""} enviado${totalFile !== 1 ? "s" : ""} com sucesso.`,
            icon: "success",
            title: "Pronto!",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          }).then(result => {
            this.setState({ file: [], pgvalue: "", fileIndex: 0 });
            this.context.showLoading("Carregando...");
            this.props.getFolders();
          });
        }
      })
      .onError(error => {

        Swal.fire({
          icon: "error",
          title: "Erro",
          html: error,
        }).then(() => {
          this.setState({ file: [], pgvalue: "", fileIndex: 0 });
        });
      });

    uploader.start();
  }
  handleFileChange(e) {
    this.setState({ file: e.target.files }, () => {
      this.executeUpload();
    });
  }

  handleUrlChange(e) {
    this.setState({ baseUrl: e.target.value });
  }

  handlePartSizeChange(e) {
    this.setState({ partsize: e.target.value });
  }

  handleNumUploadsChange(e) {
    this.setState({ numuploads: e.target.value });
  }

  handleTransferAccelerationChange(e) {
    this.setState({ ta: e.target.checked });
  }

  render() {
    const totalFile = this.state.file?.length ? this.state.file?.length : 1;

    const { pgvalue, fileIndex } = this.state;
    return (
      <div className="precos-e-servicos-file-card" onClick={this.handleClick}>
        <label for="fileinput" className="labelCreateFile">
          <div className="createFileFiv">
            <FaCirclePlus size={50} color={pgvalue ? "#D3D3D3" : "#0394db"} />
            <div>
              <p style={{ color: pgvalue ? "#D3D3D3" : "inherit" }}>Click aqui para adicionar um arquivo</p>
              <input disabled={pgvalue !== ""} id="fileinput" type="file" name="userImage" multiple onChange={this.handleFileChange} />
            </div>
          </div>
        </label>
        <div style={{ padding: "10px", margin: "10px" }}>
          <strong style={{ display: "block" }}></strong>
          <br />
          <span id="output">
            <ProgressBar now={pgvalue} label={`${pgvalue}% ` + (totalFile > 1 ? ` - ${fileIndex}/${totalFile}` : "")} variant="success" />
          </span>
        </div>
      </div>
    );
  }
}

export default CreateFile;
