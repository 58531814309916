export class Uploader {
  constructor(options) {
    this.folder = options.folder;
    this.chunkSize = 20 * 1024 * 1024;
    this.timeout = 0;
    this.file = options.file;
    this.parts = [];
    this.uploadId = "";
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    this.baseURL = process.env.REACT_APP_LABS_API;
    this.statusUpload = "";
  }

  start() {
    this.initialize();
  }

  async initialize() {
    for (let i = 0; i < this.file.length; i++) {
      this.fileIndex = i;

      const videoInitializationUploadInput = {
        folder: this.folder,
        fileName: this.file[i].name,
        access_token: localStorage.getItem("token"),
      };

      const initializeResponse = await this.fetchRequest("fileS3/upload/Initialize.php", "POST", videoInitializationUploadInput);
      const AWSFileDataOutput = await initializeResponse.json();

      this.uploadId = AWSFileDataOutput.uploadId;

      var result = await this.handleUpload();
      if (this.statusUpload == "error") {
        break;
      }
    }
    if (this.statusUpload !== "error") {
      this.onProgressFn({
        pgvalue: "",
        status: "end",
      });
      
    }
    await this.clean();
  }
  async handleUpload() {
    const partsArray = { Parts: [] };
    var tryAgain = 0;

    var totalChunks = Math.ceil(this.file[this.fileIndex].size / this.chunkSize);

    for (let partNumber = 1; partNumber <= totalChunks; partNumber++) {
      this.handleProgress(partNumber, { type: "progress" });
      const start = (partNumber - 1) * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.file[this.fileIndex].size);
      const chunk = this.file[this.fileIndex].slice(start, end);
      const partResponse = await this.uploadPart(partNumber, chunk, false);
      if (partResponse?.result && (partResponse?.status != 400 || partResponse?.status != 500)) {
        partsArray.Parts.push({
          PartNumber: partNumber,
          ETag: partResponse.result.replace(/^\"|\"$/g, ""),
        });
      }
      if (partResponse?.status == 400 || partResponse?.status == 500 || !partResponse) {
        this.onErrorFn("Erro ao realizar o upload do arquivo");
        this.statusUpload = "error";
        break;
      }
    }
    if (this.statusUpload == "error") {
      return;
    }
    const finalizeResponse = await this.sendCompleteRequest(partsArray);

    if (finalizeResponse.status != 200) {
      this.onErrorFn(`falha ao realizar upload do arquivo`);
      this.statusUpload = "error";
    }
    return;
  }
  async clean() {
    this.folder = [];
    this.file = [];
    this.parts = [];
    this.uploadId = "";
    this.statusUpload = "";
  }
  handleProgress(part, event) {
    if (this.file) {
      const percentage = Math.min(
        Math.round(((this.fileIndex + part / Math.ceil(this.file[this.fileIndex].size / this.chunkSize)) / this.file.length) * 100),
        100,
      );

      this.onProgressFn({
        percentage: percentage - 1,
        fileIndex: this.fileIndex + 1,
      });
    }
  }

  async uploadPart(partNumber, data, tryAgain = 0) {
    const formData = new FormData();    
    formData.append("access_token", localStorage.getItem("token")) 
    formData.append("folder", this.folder);
    formData.append("fileName", this.file[this.fileIndex].name);
    formData.append("partNumber", partNumber);
    formData.append("uploadId", this.uploadId);
    formData.append("try", tryAgain);
    formData.append("fileChunk", data);
    const response = await fetch(process.env.REACT_APP_LABS_API + "fileS3/upload/upload_part.php", {
      method: "POST",
      body: formData,
    });

    var dataResult = await response.json();
    if (dataResult?.status != undefined || dataResult?.status != null) {
      return dataResult;
    } else {
      return null;
    }
  }

  async fetchRequest(url, method, data) {
    const response = await fetch(this.baseURL + url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  }

  async sendCompleteRequest(parts) {
    const videoFinalizationMultiPartInput = {
      uploadId: this.uploadId,
      parts: parts,
      try: this.try,
      folder: this.folder,
      fileName: this.file[this.fileIndex].name,
      access_token: localStorage.getItem("token"),
    };

    const result = await this.fetchRequest("fileS3/upload/finalize.php", "POST", videoFinalizationMultiPartInput);
    const resultJson = await result.json();
    return resultJson;
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;

    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }
}
