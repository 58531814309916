import React, { Component } from "react";
import { Link } from "react-router-dom";
import AlertNews from "../main/alert-news/alert-news";
import Swal from "sweetalert2";
import AppContext from "../appContext";

class Module extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    Module.contextType = AppContext;
    this.clickPromo = this.clickPromo.bind(this);
  }

  clickPromo(url, target) {
    let cnpj = this.context.userSession.conta.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    let urlPadrao = this.props.urlPadrao;
    let urlOk = urlPadrao.replace("/login/sso", "/logoff");
    Swal.fire({
      html: `<p>Deseja ser redirecionado para o site Essilor Promo já autenticado com o CNPJ <strong>${cnpj}</strong>?</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00518b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        window.open(url, "_blank");
      } else {
        window.open(urlOk !== "" ? urlOk : url, "_blank");
      }
    });
  }

  render() {
    return (
      <li className="module" key={this.props.index}>
        <div className="module_logo">
          <AlertNews data={this.props.dataLimiteNovidade} mensagem={this.props.mensagemNovidade} />
          <img src={this.props.logo} alt="" className="module_logo-img" />
        </div>

        {this.props.nome === "Essilor Promo" ? (
          <>
            <button className="module_button" onClick={() => this.clickPromo(this.props.url, this.props.target)}>
              <span className="module_button-text">{this.props.message}</span>
            </button>
          </>
        ) : (
          <>
            <a href={this.props.url} target={this.props.target}>
              <button className="module_button">
                <span className="module_button-text">{this.props.message}</span>
              </button>
            </a>
          </>
        )}
      </li>
    );
  }
}

export default Module;
