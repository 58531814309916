import React, { Component } from "react";

import PrecosEServicosSelect from "./inputs/precos-e-servicos-select";
import PrecosEServicosFileCard from "./card/precos-e-servicos-card";
import AppContext from "../appContext";
import Back from "../main/back";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import CreateFile from "./createFile";

import "./precos-e-servicos.css";
import PrecosEServicosSearchInput from "./inputs/precos-e-servicos-search-input";

class PrecosEServicos extends Component {
  constructor() {
    super();
    this.state = {
      ...this.state,
      redirect: false,
      redirectL: false,
      folderName: "",
      fileName: "",
      userFolders: "",
      userFiles: [],
      fileExtension: "",
      fileExtensions: [],
      financialFiles: [],
      filteredFinancialFiles: [],
      financialCenterPermission: false,
      gestaoArquivos: false,
    };

    PrecosEServicos.contextType = AppContext;

    this.showNoAccessMessage = this.showNoAccessMessage.bind(this);
    this.getAllUserFiles = this.getAllUserFiles.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this);
    this.getFolders = this.getFolders.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      let role = false;
      role = this.context.userSession.modulos.find(element => element === "Preços e Serviços");
      if (!role) {
        this.context.hideLoading();

        this.showNoAccessMessage();
      } else {    
        this.context.showLoading("Carregando...");

        await this.getFolders();
      }
    } else {
      this.state = { redirect: true };
    }
  }
 async  getFolders() {
    const email = this.context.userSession.email;
    const requestOptions1 = {
      method: "POST",
      body: JSON.stringify({
        email: email,
        motivo: "financeiroArquivos",
      }),
    };
    try {
      fetch(process.env.REACT_APP_LABS_API + "get_users_data.php", requestOptions1)
        .then(response => {
          return response.json();
        })
        .then(async json => {
          this.state.financialCenterPermission = true;
          if (json.items[0].FinancialCenterFolders_c !== null) {
            this.state.userFolders = json.items[0].FinancialCenterFolders_c.split(", ");
          } else {
            this.context.hideLoading();

            this.showNoAccessMessage();
          }
          if (json.items[0].GestaoArquivosPeS_c == true) {
            this.setState({ gestaoArquivos: true });
          }
          if (this.state.userFolders) await this.getAllUserFiles();
        })
        .catch(error => {
          console.error("Erro ao buscar os dados: ", error);
          this.context.hideLoading();
        });
    } catch (error) {
      this.context.hideLoading();
    } finally {
    }
    return;
  }
  showNoAccessMessage() {
    Swal.fire({
      icon: "warning",
      title: "Acesso não permitido",
      html: "Solicite acesso à tabela de preços Essilor e/ou extrato de serviços DEO e CEO realizados, enviando um email para <b>precosedescontos@essilor.com.br</b> e informando seu usuário Conecta (email usado para realizar o login do site).",
      confirmButtonColor: "#00518b",
      customClass: {
        confirmButton: "x-follow-order__filter-btn",
      },
      onClose: () => (window.location = "/home"),
    });
  }

  async getAllUserFiles() {
   
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        access_token: localStorage.getItem("token"),
        folders: this.state.userFolders,
      }),
    };

    try {
      fetch(process.env.REACT_APP_LABS_API + `get_files_financial.php`, requestOptions)
        .then(response => {

          if (response.status !== 200) {
            return false;
          }

          return response.json();
        })
        .then(json => {
          if (json) {
            this.setState({ financialFiles: json });
            this.setState({ filteredFinancialFiles: json });

            const extensions = [
              ...new Set(
                json.map(file => {
                  let filename = file.filename;
                  let ultimoPonto = filename.lastIndexOf(".");
                  return filename.substring(ultimoPonto + 1);
                }),
              ),
            ];
            this.setState({ fileExtensions: extensions }, () =>{
              this.handleFilter();
              

            });
            
          } else {
            Swal.fire({
              icon: "error",
              title: "Ocorreu um erro na solicitação",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          }
        })
    } catch (error) {this.context.hideLoading();}
    return;
  }

  handleFilter(event, stateName) {
    if (stateName) {
      this.state[stateName] = event.currentTarget.value;
    }

    const filter = (folder, name, extension) => {
      const uniqueFiles = [];
      const existingFilenames = new Set();

      this.state.financialFiles.forEach(file => {
        let filename = file.filename;
        let ultimoPonto = filename.lastIndexOf(".");
        let extensionToCompare = filename.substring(ultimoPonto + 1);
        if (
          file["folder"].includes(folder) &&
          file["filename"].toLowerCase().includes(name.toLowerCase()) &&
          (extension ? extensionToCompare === extension : true)
        ) {
          const filename = file["filename"];
          if (!existingFilenames.has(filename)) {
            existingFilenames.add(filename);
            uniqueFiles.push(file);
          }
        }
      });
this.context.hideLoading();
      return uniqueFiles;
    };

    this.setState({ filteredFinancialFiles: filter(this.state.folderName, this.state.fileName, this.state.fileExtension) });
  }

  cleanFilters() {
    this.setState({
      filteredFinancialFiles: this.state.financialFiles,
      folderName: "",
      fileName: "",
      fileExtension: "",
    });
  }

  render() {
    const { redirectL, redirect } = this.state;

    if (redirect) {
      return <Redirect to="/home" />;
    }
    if (redirectL) {
      return <Redirect to="/home" />;
    }
    return (
      <div id="main-body" className="container">
        <div className="back">
          <Back />
        </div>

        <div className="precos-e-servicos_main">
          <div className="precos-e-servicos_container">
            <div className="precos-e-servicos-intro">
              <h1 className="precos-e-servicos-title">Conecta Preços e Serviços</h1>
              <span className="precos-e-servicos-shape"></span>
            </div>

            <div className="precos-e-servicos-text">
              <p className="precos-e-servicos-p">
                Neste módulo você poderá fazer o download da lista de preços Essilor e dos extratos dos serviços de Design Fee e CEO.
                <br />
                Caso tenha alguma dúvida, entre em contato com <b>precosedescontos@essilor.com.br</b> (para lista de preços) e com{" "}
                <b>faturamentoceoedeo@essilor.com.br</b> (para os extratos de serviços). Boas vendas!
              </p>
            </div>

            <div className="filters-container">
              <div className="row mb-3 preco-e-servicos-filter-div">
                <div className="col-xs-12 col-md-3 mt-3">
                  <PrecosEServicosSelect
                    label="Pasta:"
                    name="download_search_folder"
                    id="download_search_folder"
                    value={this.state.folderName}
                    onChange={event => this.handleFilter(event, "folderName")}
                    textSelected="Selecione"
                    onKeyDown={() => {}}
                    itens={this.state.userFolders || []}
                  />
                </div>
                <div className="col-xs-12 col-md-3 mt-3">
                  <PrecosEServicosSelect
                    label="Tipo de arquivo:"
                    name="download_file_type"
                    id="download_file_type"
                    value={this.state.fileExtension}
                    onChange={event => this.handleFilter(event, "fileExtension")}
                    textSelected="Selecione"
                    itens={this.state.fileExtensions || []}
                  />
                </div>

                <div className="col-xs-12 col-md-3 mt-3">
                  <PrecosEServicosSearchInput
                    label="Nome:"
                    name="search_file_name"
                    value={this.state.fileName}
                    onChange={event => this.handleFilter(event, "fileName")}
                  />
                </div>
                <div className="col-xs-12 col-md-3 mt-5 pt-1">
                  <strong>
                    <a className="limparFiltro" onClick={this.cleanFilters}>
                      Limpar Filtros
                    </a>
                  </strong>
                </div>
              </div>
            </div>

            <div className="files-container">
              {this.state.folderName && this.state.gestaoArquivos == true && <CreateFile folderSelect={this.state.folderName} getFolders={this.getFolders} />}
              {this.state.filteredFinancialFiles?.map((file, index) => {
                return (
                  <PrecosEServicosFileCard
                    key={index + file.folder + file.filename}
                    file={file}
                    gestaoArquivos={this.state.gestaoArquivos}
                    getFolders={this.getFolders}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrecosEServicos;
