import Swal from "sweetalert2";

async function deleteFile(folder, file) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ folder: folder, fileName: file, access_token: localStorage.getItem("token") }),
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_LABS_API}fileS3/delete/deleteFile.php`, requestOptions);

    const json = await response.json();
    if (json.status != 200) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro na solicitação",
        text: "Tente novamente mais tarde.",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
    } else {
      return json;
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Ocorreu um erro na solicitação",
      text: "Tente novamente mais tarde.",
      confirmButtonColor: "#00518b",
      customClass: {
        confirmButton: "x-follow-order__filter-btn",
      },
    });
  }
}

export { deleteFile };
