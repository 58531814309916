import React, { Component } from "react";

//Import CSS
import "./order.css";

//Import internal components
import AppContext from "../../appContext";
import Back from "./back";
import OrderStage from "./order-stage";
import OrderSummary from "./summary/order-summary";

import OrderDetail from "./1_order_detail/order-detail";
import OrderLens from "./2_order_lens/order-lens";
import OrderPerscription from "./3_order_perscription/order-perscription";
import OrderFrame from "./4_order_frame/order-frame";
import OrderConfirm from "./5_order_confirm/order-confirm";

//Import external components
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import Alert from "sweetalert2";
import moment from "moment";
import { redirectPage } from "../../components/utils/redirectpage";

//Import images

class WOOrder extends Component {
  constructor(props) {
    super(props);

    WOOrder.contextType = AppContext;

    this.state = {
      direction: "",
      lente_montagem: "S",
      prescricao_prisma: "N",
      prescricao_olhos: "ambos",
      redirect: false,
      redirectL: false,
      redirectWO: false,
      returnToChoices: false,
      laboratorio: "",
      inProgress: true,
      loading: false,
      name: "",
      cpf: "",
      addCrm: false,
      costumer: {},
      crm_enable: false,
      expanded: false,
      stage: 1,
      quantidade: 1,

      control_dnpPerto: false,

      control_prescricao_ini_clientes: false,
      control_prescricao_dis_leitura: false,
      control_prescricao_cvp: false,
      control_prescricao_cro: false,
      control_prescricao_ang_pantoscopico: false,
      control_prescricao_curva_arm: false,
      control_prescricao_coeficiente: false,
      control_prescricao_estabilidade: false,
      control_prescricao_dis_prog: false,
      control_prescricao_dis_vert: false,
      control_prescricao_olho_dom: false,

      control_horizontal: false,
      control_vertical: false,
      control_ponte: false,
      control_diagonal: false,
      control_modelo: false,

      prescricao_olho_esquerdo_dnp: "",
      prescricao_olho_esquerdo_altura: "",
      prescricao_olho_esquerdo_dnp_perto: "",
      prescricao_olho_direito_dnp: "",
      prescricao_olho_direito_altura: "",
      prescricao_olho_direito_dnp_perto: "",

      prescricao_olho_direito_et: "",
      prescricao_olho_esquerdo_et: "",
      prescricao_olho_direito_ct: "",
      prescricao_olho_esquerdo_ct: "",
      prescricao_olho_direito_base: "",
      prescricao_olho_esquerdo_base: "",

      prescricao_ini_clientes: "",
      prescricao_dis_leitura: "",
      prescricao_cvp: "",
      prescricao_cro_direito: "",
      prescricao_cro_esquerdo: "",
      prescricao_ang_pantoscopico: "",
      prescricao_curva_arm: "",
      prescricao_coeficiente: "",
      prescricao_estabilidade: "",
      prescricao_dis_prog: "",
      prescricao_dis_vert_direito: "",
      prescricao_dis_vert_esquerdo: "",
      prescricao_olho_dom: "",
      remoteEdge: "",
      remoteEdgeDisabled: false,
      tratamentoLabel: null,
      pedidoColorirNaOtica: false,
      cor_armacao: "",
      cores_armacao: [],
      marcas_armacao: [],
      marca_armacao_value: "",
      marca_armacao_outras: "",
    };

    if (localStorage.getItem("token") !== null) {
      var token = localStorage.getItem("token");

      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      var jwt = JSON.parse(jsonPayload);
      this.setState({ name: jwt.usuario.nome, cpf: jwt.usuario.cpf });

      var myHeaders = new Headers({
        Authorization: "Bearer " + localStorage.getItem("session_token"),
      });

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({ email: jwt.usuario.email }),
        headers: myHeaders,
      };

      fetch(process.env.REACT_APP_LABS_API + "getUserOEC.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          this.setState({ name: json.name, cpf: jwt.usuario.cpf });
        });
      const role = jwt.modulos.find(element => element === "Pedidos");
      if (!role) {
        this.state = { redirect: true };
      }
      if (this.props.location.state === undefined) {
        this.state = { redirectWO: true };
      }
    } else {
      this.state = { redirectL: true };
    }

    this.changeStage = this.changeStage.bind(this);
    this.getModulesCategorias = this.getModulesCategorias.bind(this);
  }
  componentDidMount() {
    redirectPage(this.context.userSession, "/order");
    this.context.showLoading();
    this.getNameAccount(this.context.userSession.conta);
    this.listarRedesOticas();
    this.getModulesCategorias();

    if (this.props.location.state !== undefined) {
      this.setState({
        laboratorio: this.props.location.state.lab,
        labName: this.props.location.state.name,
        labTelefone: this.props.location.state.telefone,
        labSite: this.props.location.state.site,
        labLogo: this.props.location.state.logo,
        labEmail: this.props.location.state.email,
        dados_empresa_faturamento: this.props.location.state.dados_empresa_faturamento,
        dados_empresa_faturamento_cnpj: this.props.location.state.dados_empresa_faturamento_cnpj,
        promo_code: this.props.location.state.promo_code,
        rpl: this.props.location.state.rpl,
        promo_proximo_par: this.props.location.state.promo_proximo_par,
        promo: this.props.location.state.promo,
        IsCor_AR: this.props.location.state.IsCor_AR,
        IsGrowmocao: this.props.location.state.IsGrowmocao,
        hasCorteRemoto: this.props.location.state.corteRemoto,
        detailOrder: this.props.location.state.detailOrder,
        colorirNaOtica: this.props.location.state.colorirNaOtica,
        dados_nome_cliente: this.props.location.state.dados_nome_cliente,
        dados_cpf_cliente: this.props.location.state.dados_cpf_cliente,
        dados_crm_medico: this.props.location.state.dados_crm_medico,
        CorteMontagemObrigatorio: this.props.location.state.CorteMontagemObrigatorio,
      });
    }

    // window.onbeforeunload = () => {
    // 	return 'Deseja realmente sair?'
    // }
  }

  componentWillUnmount() {
    // window.onbeforeunload = null
  }

  listarRedesOticas = cnpjOtica => {
    if (this.context.userSession.modulos.includes("Consulta Rede")) {
      const requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: this.props.location.state.lab,
        },
      };

      fetch(process.env.REACT_APP_ESSILOR_API + "gestao-laboratorios/v1/redes", requestOptions).then(response => {
        if (response.status === 403) {
          Swal.fire({
            title: "Aviso",
            text: "Laboratório não disponível para este usuário no momento.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          }).then(result => {
            if (result.value) {
              this.setState({ redirect: true });
            }
          });
        } else {
          return response.json().then(json => {
            if (json.participantes) {
              this.setState({
                redeOticas: true,
              });
            } else {
              this.setState({
                redeOticas: false,
              });
            }
          });
        }
      });
    } else {
      this.setState({
        redeOticas: false,
      });
    }
  };

  getModulesCategorias() {
    var mod = [];
    var especialista = this.context.especialista && this.context.especialista.includes("VARILUX") ? true : false;
    const requestOptions1 = {
      method: "POST",
      body: JSON.stringify({
        modulos: this.context.userSession.modulos,
        especialista: especialista,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_modules_categories.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.items) {
          json.items.map((item, index) => {
            item.modulos.map((mods, index) => {
              if (!mods.OticasPilotos_c) {
                mod.push(mods);
              } else {
                const contas = mods.OticasPilotos_c.split(";");
                if (contas.some(elem => elem.includes(this.context.userSession.conta))) {
                  mod.push(mods);
                }
              }
            });
          });

          var crmFlag = false;
          this.setState({
            crm_enable: false,
            addCrm: false,
          });
          // }
        }
      });
    if (!this.state.promo_proximo_par) {
      this.context.hideLoading();
    }
  }

  obterServicos = () => {
    let servicos = [];

    if (this.state.lente_servicos_cod) {
      let ANTIRREFLEXO = {
        codigo: this.state.lente_servicos_cod,
        descricao: this.state.lente_servicos_value,
        tipo: "ANTIRREFLEXO",
      };
      servicos.push(ANTIRREFLEXO);
    }

    if (this.state.lente_coloracao_cod && this.state.pedidoColorirNaOtica == false) {
      let COLORACAO = {
        codigo: this.state.lente_coloracao_cod,
        descricao: this.state.lente_coloracao_value,
        tipo: "COLORACAO",
      };
      servicos.push(COLORACAO);
    }

    if (this.state.lente_corte_cod) {
      let MONTAGEM = {
        codigo: this.state.lente_corte_cod,
        descricao: this.state.lente_corte_value,
        tipo: "MONTAGEM",
      };

      servicos.push(MONTAGEM);
    }

    if (this.state.growmocao) {
      let OUTROS = {
        codigo: this.state.growmocao_cod,
        descricao: this.state.growmocao_value,
        tipo: "OUTROS",
      };

      servicos.push(OUTROS);
    }

    return servicos;
  };

  obterReceita = () => {
    let receita = {};
    if (this.state.prescricao_olhos != "esquerdo") {
      receita.olhoDireito = this.obterOlhoDireito();
    }

    if (this.state.prescricao_olhos != "direito") {
      receita.olhoEsquerdo = this.obterOlhoEsquerdo();
    }

    return receita;
  };

  obterOlhoDireito = () => {
    var olhoDireito = {
      grauEsferico: this.state.prescricao_olho_direito_esferico === undefined ? "" : this.state.prescricao_olho_direito_esferico,
      grauCilindro: this.state.prescricao_olho_direito_cilindrico ? this.state.prescricao_olho_direito_cilindrico : "0.00",
      eixo: this.state.prescricao_olho_direito_eixo === undefined ? "" : this.state.prescricao_olho_direito_eixo,
      adicao:
        this.state.prescricao_olho_direito_adicao === undefined || this.state.lente_tp_produto_value === "Visão Simples"
          ? ""
          : this.state.prescricao_olho_direito_adicao,
      dnp: this.state.prescricao_olho_direito_dnp === undefined ? "" : this.state.prescricao_olho_direito_dnp.toString(),
      altura: this.state.prescricao_olho_direito_altura === undefined ? "" : this.state.prescricao_olho_direito_altura.toString(),
      codigoLinhaProduto: this.state.lente_esc_produto === undefined ? "" : this.state.lente_esc_produto,
      rpl: this.state.rpl ? this.state.rpl : "",
      tipoProducao: this.state.lente_tp_producao,
      tipoProduto: this.state.lente_tp_produto,
      base: this.state.prescricao_olho_direito_base,
      ct: this.state.prescricao_olho_direito_ct,
      et: this.state.prescricao_olho_direito_et,
    };

    olhoDireito = this.obterPrismaDireito(olhoDireito);

    return olhoDireito;
  };

  obterOlhoEsquerdo = () => {
    var olhoEsquerdo = {
      grauEsferico: this.state.prescricao_olho_esquerdo_esferico === undefined ? "" : this.state.prescricao_olho_esquerdo_esferico,
      grauCilindro: this.state.prescricao_olho_esquerdo_cilindrico ? this.state.prescricao_olho_esquerdo_cilindrico : "0.00",
      eixo: this.state.prescricao_olho_esquerdo_eixo === undefined ? "" : this.state.prescricao_olho_esquerdo_eixo,
      adicao:
        this.state.prescricao_olho_esquerdo_adicao === undefined || this.state.lente_tp_produto_value === "Visão Simples"
          ? ""
          : this.state.prescricao_olho_esquerdo_adicao,
      dnp: this.state.prescricao_olho_esquerdo_dnp === undefined ? "" : this.state.prescricao_olho_esquerdo_dnp.toString(),
      altura: this.state.prescricao_olho_esquerdo_altura === undefined ? "" : this.state.prescricao_olho_esquerdo_altura.toString(),
      codigoLinhaProduto: this.state.lente_esc_produto === undefined ? "" : this.state.lente_esc_produto,
      rpl: this.state.rpl ? this.state.rpl : "",
      tipoProducao: this.state.lente_tp_producao,
      tipoProduto: this.state.lente_tp_produto,
      base: this.state.prescricao_olho_esquerdo_base,
      ct: this.state.prescricao_olho_esquerdo_ct,
      et: this.state.prescricao_olho_esquerdo_et,
    };

    olhoEsquerdo = this.obterPrismaEsquerdo(olhoEsquerdo);

    return olhoEsquerdo;
  };

  obterPrismaDireito = olhoDireito => {
    if (this.state.prescricao_olho_direito_pris_h) {
      olhoDireito.valorPrismaHorizontal = this.state.prescricao_olho_direito_pris_h;
    }

    if (this.state.prescricao_olho_direito_base_h) {
      olhoDireito.eixoPrismaHorizontal = this.state.prescricao_olho_direito_base_h;
    }

    if (this.state.prescricao_olho_direito_pris_v) {
      olhoDireito.valorPrismaVertical = this.state.prescricao_olho_direito_pris_v;
    }

    if (this.state.prescricao_olho_direito_base_v) {
      olhoDireito.eixoPrismaVertical = this.state.prescricao_olho_direito_base_v;
    }

    return olhoDireito;
  };

  obterPrismaEsquerdo = olhoEsquerdo => {
    if (this.state.prescricao_olho_esquerdo_pris_h) {
      olhoEsquerdo.valorPrismaHorizontal = this.state.prescricao_olho_esquerdo_pris_h;
    }

    if (this.state.prescricao_olho_esquerdo_base_h) {
      olhoEsquerdo.eixoPrismaHorizontal = this.state.prescricao_olho_esquerdo_base_h;
    }

    if (this.state.prescricao_olho_esquerdo_pris_v) {
      olhoEsquerdo.valorPrismaVertical = this.state.prescricao_olho_esquerdo_pris_v;
    }

    if (this.state.prescricao_olho_esquerdo_base_v) {
      olhoEsquerdo.eixoPrismaVertical = this.state.prescricao_olho_esquerdo_base_v;
    }

    return olhoEsquerdo;
  };

  obterParametros = () => {
    let parametros = {};

    if (
      this.state.prescricao_olhos != "esquerdo" &&
      (this.state.prescricao_olho_direito_dnp_perto ||
        this.state.prescricao_dis_vert_direito ||
        this.state.prescricao_cro_direito ||
        this.state.prescricao_ang_pantoscopico ||
        this.state.prescricao_curva_arm ||
        this.state.prescricao_dis_leitura ||
        this.state.prescricao_cvp ||
        this.state.prescricao_wmap)
    ) {
      parametros.olhoDireito = this.obterOlhoDireitoParametros();
    }

    if (
      this.state.prescricao_olhos != "direito" &&
      (this.state.prescricao_olho_esquerdo_dnp_perto ||
        this.state.prescricao_dis_vert_esquerdo ||
        this.state.prescricao_cro_esquerdo ||
        this.state.prescricao_ang_pantoscopico ||
        this.state.prescricao_curva_arm ||
        this.state.prescricao_dis_leitura ||
        this.state.prescricao_cvp ||
        this.state.prescricao_wmap)
    ) {
      parametros.olhoEsquerdo = this.obterOlhoEsquerdoParametros();
    }

    if (this.state.prescricao_olho_dom) {
      parametros.olhoDominante = this.obterOlhoDominanteParametros();
    }

    return parametros;
  };

  obterOlhoDireitoParametros = () => {
    return {
      dnpPerto: this.state.prescricao_olho_direito_dnp_perto ? this.state.prescricao_olho_direito_dnp_perto.toString() : "",
      distanciaVertice: this.state.prescricao_dis_vert_direito === undefined ? "" : this.state.prescricao_dis_vert_direito,
      cro: this.state.prescricao_cro_direito === undefined ? "" : this.state.prescricao_cro_direito,
      anguloPantoscopico: this.state.prescricao_ang_pantoscopico === undefined ? "" : this.state.prescricao_ang_pantoscopico,
      anguloCurvatura: this.state.prescricao_curva_arm === undefined ? "" : this.state.prescricao_curva_arm,
      distanciaLeitura: this.state.prescricao_dis_leitura === undefined ? "" : this.state.prescricao_dis_leitura,
      coeficienteHE: this.state.prescricao_coeficiente,
      coeficienteST: this.state.prescricao_estabilidade,
      distanciaProgressao: this.state.prescricao_dis_prog,
      cvp: this.state.prescricao_cvp === undefined ? "" : this.state.prescricao_cvp,
      vmap: this.state.prescricao_wmap === undefined ? "" : this.state.prescricao_wmap,
    };
  };

  obterOlhoEsquerdoParametros = () => {
    return {
      dnpPerto: this.state.prescricao_olho_esquerdo_dnp_perto ? this.state.prescricao_olho_esquerdo_dnp_perto.toString() : "",
      distanciaVertice: this.state.prescricao_dis_vert_esquerdo === undefined ? "" : this.state.prescricao_dis_vert_esquerdo,
      cro: this.state.prescricao_cro_esquerdo === undefined ? "" : this.state.prescricao_cro_esquerdo,
      anguloPantoscopico: this.state.prescricao_ang_pantoscopico === undefined ? "" : this.state.prescricao_ang_pantoscopico,
      anguloCurvatura: this.state.prescricao_curva_arm === undefined ? "" : this.state.prescricao_curva_arm,
      distanciaLeitura: this.state.prescricao_dis_leitura === undefined ? "" : this.state.prescricao_dis_leitura,
      coeficienteHE: this.state.prescricao_coeficiente,
      coeficienteST: this.state.prescricao_estabilidade,
      distanciaProgressao: this.state.prescricao_dis_prog,
      cvp: this.state.prescricao_cvp === undefined ? "" : this.state.prescricao_cvp,
      vmap: this.state.prescricao_wmap === undefined ? "" : this.state.prescricao_wmap,
    };
  };

  obterOlhoDominanteParametros = () => {
    return this.state.prescricao_olho_dom ? this.state.prescricao_olho_dom : "";
  };

  obterCodigoParticipante = () => {
    return {
      codigoParticipante: this.state.codigoParticipante ? this.state.codigoParticipante : "",
    };
  };

  obterPromo = () => {
    return {
      voucher: this.state.promo_code ? this.state.promo_code : "",
      idPar: this.state.promo_n_par ? this.state.promo_n_par : "",
    };
  };

  getNameAccount(account) {
    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: account }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.setState({
          company: json.nome ? json.nome : "",
          accountCode: this.context.userSession.conta,
        });
      });
  }

  FormataStringData(data) {
    var dia = data.split("/")[0];
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];

    return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  obterDadosCustomer = () => {
    var lastPurchaseDate = moment().format("YYYY-MM-DD");
    if (this.state.dados_nascimento) {
      var birthDate = this.FormataStringData(this.state.dados_nascimento);
    }

    const body = {
      name: this.state.dados_nome_cliente,
      email: this.state.dados_email,
      status: "Conta Existente",
      cpf: this.state.dados_cpf_cliente,
      cellphone: this.state.dados_telefone,
      doctorCRM: this.state.dados_crm_medico === undefined ? "" : this.state.dados_crm_medico,
      lastPurchaseDate: lastPurchaseDate,
      birthDate: birthDate,
      isActive: true,
      accountCode: this.context.userSession.conta,
      lensType: this.state.lente_tp_produto_value,
      company: this.state.company,
    };

    return body;
  };

  obterBodyOrderSalesForceOlhoDireito = orderItems => {
    // if (this.state.prescricao_olhos != "esquerdo") {
    //   orderItems.push({
    //     unitPrice: this.state.valorLiquido.replace("R$ ", "").replace(",", "."),
    //     quantity: 0,
    //     cylindricalGrade: this.state.prescricao_olho_direito_cilindrico ? this.state.prescricao_olho_direito_cilindrico : "0.00",
    //     sphericalGrade: this.state.prescricao_olho_direito_esferico,
    //     addition: this.state.prescricao_olho_direito_adicao ? this.state.prescricao_olho_direito_adicao : "",
    //     eye: "Direito",
    //     axis: this.state.prescricao_olho_direito_eixo ? this.state.prescricao_olho_direito_eixo : "",
    //     orderItemId: "D",
    //   });
    // }
  };

  obterBodyOrderSalesForceOlhoEsquerdo = orderItems => {
    // if (this.state.prescricao_olhos != "direito") {
    //   orderItems.push({
    //     unitPrice: this.state.valorLiquido.replace("R$ ", "").replace(",", "."),
    //     quantity: 0,
    //     cylindricalGrade: this.state.prescricao_olho_esquerdo_cilindrico ? this.state.prescricao_olho_esquerdo_cilindrico : "0.00",
    //     sphericalGrade: this.state.prescricao_olho_esquerdo_esferico,
    //     addition: this.state.prescricao_olho_esquerdo_adicao ? this.state.prescricao_olho_esquerdo_adicao : "",
    //     eye: "Esquerdo",
    //     axis: this.state.prescricao_olho_esquerdo_eixo ? this.state.prescricao_olho_esquerdo_eixo : "",
    //     orderItemId: "E",
    //   });
    // }
  };

  obterBodyOrderSalesForce = () => {
    // var effectiveDate = moment().format("YYYY-MM-DD");
    // var order = {
    //   accountCode: this.context.userSession.conta,
    //   leadCode: this.state.dados_cpf_cliente,
    //   effectiveDate: effectiveDate,
    //   productDescription: this.state.descricao,
    //   eyeglassFrameType: this.state.frame_tipo_arm_value ? this.state.frame_tipo_arm_value : "",
    //   productType: this.state.lente_tp_produto_value,
    //   productionType: this.state.lente_tp_producao_value,
    //   status: "Rascunho",
    //   orderNumber: this.state.dados_num_pedido,
    //   orderItems: [],
    // };
    // this.obterBodyOrderSalesForceOlhoDireito(order.orderItems);
    // this.obterBodyOrderSalesForceOlhoEsquerdo(order.orderItems);
    // return order;
  };

  obterCor() {
    var cores = {
      descricao: this.state.pedidoColorirNaOtica != true ? this.state.cor_value : "",
      codigo: this.state.pedidoColorirNaOtica != true ? this.state.cor_cod : "",
      codigoRef: "",
    };

    return cores;
  }

  obterBody = () => {
    var body = {
      idPedidoOtica: this.state.dados_num_pedido,
      observacao: this.state.pedidoColorirNaOtica
        ? (this.state.descricao ? this.state.descricao : "") + " -  Cliente escolheu colorir na ótica."
        : this.state.descricao
        ? this.state.descricao
        : "",
      servicos: this.obterServicos(),
      receita: this.obterReceita(),
      corColoracao: this.obterCor(),
      armacao: {
        vertical: this.state.frame_info_arm_vertical ? this.state.frame_info_arm_vertical : "",
        horizontal: this.state.frame_info_arm_horizontal ? this.state.frame_info_arm_horizontal : "",
        ponte: this.state.frame_info_arm_ponte ? this.state.frame_info_arm_ponte : "",
        diametroMaior: this.state.frame_info_arm_diametro ? this.state.frame_info_arm_diametro : "",
        tipo: this.state.frame_tipo_arm ? this.state.frame_tipo_arm : "",
        modelo: this.state.frame_modelo ? this.state.frame_modelo : "",
        cor: this.state.cor_armacao ? this.state.cor_armacao : "",
        referencia: this.state.frame_modelo_v2 ? this.state.frame_modelo_v2 : "",
        leitura: this.state.remoteEdge ? this.state.remoteEdge : "",
      },

      parametros: this.obterParametros(),
      paciente: {
        nome: this.state.dados_nome_cliente,
        cpf: this.state.dados_cpf_cliente,
        iniciaisPaciente: this.state.prescricao_ini_clientes,
      },
      medico: {
        nome: this.state.dados_nome_medico === undefined ? "" : this.state.dados_nome_medico,
        crm: this.state.dados_crm_medico === undefined ? "" : this.state.dados_crm_medico,
      },
      vendedor: {
        nome: this.state.name,
        cpf: this.state.cpf,
      },
    };
    if (this.state.marca_armacao_value) {
      body.armacao.marca = this.state.marca_armacao_value
        ? this.state.marca_armacao_outras && this.state.marca_armacao_value === "OUTRAS"
          ? this.state.marca_armacao_outras
          : this.state.marca_armacao_value
        : "";
    }
    if (this.state.codigoParticipante) {
      body.programaLaboratorio = this.obterCodigoParticipante();
    }

    if (this.state.promo_code) {
      body.promocao = this.obterPromo();
    }

    if (this.state.quantidade >= 2) {
      body.quantidade = this.state.quantidade;
    }

    return body;
  };

  checkDecimal = value => {
    if (value.toString().split(".")[1] !== undefined) {
      return value.toString().split(".")[1].length;
    } else if (value.toString().split(",")[1] !== undefined) {
      return value.toString().split(".")[1].length;
    } else {
      return 0;
    }
  };

  scrollTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  validationStage1(stage, direction) {
    var valido = true;

    if (!this.state.dados_num_pedido) {
      valido = false;
    }

    // if (this.state.addCrm) {
    //   if (!this.state.dados_nome_cliente) {
    //     valido = false;
    //   }

    //   if (!this.state.dados_cpf_cliente) {
    //     valido = false;
    //   }

    //   if (!this.state.dados_email && !this.state.dados_telefone) {
    //     valido = false;
    //   }
    // }

    if (valido && stage && direction) {
      this.setState({ stage: stage, direction: direction }, this.scrollTop());
    }

    return valido;
  }

  validationStage2(stage, direction) {
    var valido = true;
    if (!this.state.lente_esc_produto) {
      valido = false;
    }

    if (document.getElementById("lente_corte")) {
      if (document.getElementById("lente_corte").required && !document.getElementById("lente_corte").value) {
        valido = false;
      }
    }

    var selectFields = document.getElementsByTagName("SELECT");
    for (var item in selectFields) {
      if (selectFields[item].required && !selectFields[item].value) {
        valido = false;
      }
    }

    if (this.state.json_parametros.length === 0) {
      valido = false;
    }

    if (this.state.promo_code && this.state.pedidoColorirNaOtica == false) {
      const tratamento = document.getElementById("lente_servicos");
      const coloracao = document.getElementById("lente_coloracao");

      if (
        (tratamento && tratamento.options?.length > 1 && tratamento.options?.selectedIndex === 0 && !tratamento.disabled) ||
        (coloracao && coloracao.options?.length > 1 && coloracao.options?.selectedIndex === 0)
      ) {
        valido = false;
      }
    } else if (this.state.promo_code) {
      const tratamento = document.getElementById("lente_servicos");

      if (tratamento && tratamento.options?.length > 1 && tratamento.options?.selectedIndex === 0 && !tratamento.disabled) {
        valido = false;
      }
    }

    if (valido && stage && direction) {
      this.setState({ stage: stage, direction: direction }, this.scrollTop());
    }

    return valido;
  }

  validationStage3(stage, direction) {
    var valido = true;

    var selectFields = document.getElementsByTagName("SELECT");
    for (var item in selectFields) {
      if (selectFields[item].required && selectFields[item].value === "") {
        valido = false;
      }
    }

    var inputFields = document.getElementsByTagName("INPUT");
    for (var item in inputFields) {
      if (inputFields[item].required && inputFields[item].value === "") {
        valido = false;
      }
    }

    var dnppertodir = document.getElementById("prescricao_olho_direito_dnp_perto");
    if (dnppertodir) {
      if ((dnppertodir.value > dnppertodir.max || dnppertodir.value < dnppertodir.min) && dnppertodir.value !== "") {
        valido = false;
      }

      if (this.checkDecimal(dnppertodir.value) > 1) {
        valido = false;
      }
    }

    var dnppertoesq = document.getElementById("prescricao_olho_esquerdo_dnp_perto");
    if (dnppertoesq) {
      if ((dnppertoesq.value > dnppertoesq.max || dnppertoesq.value < dnppertoesq.min) && dnppertoesq.value !== "") {
        valido = false;
      }

      if (this.checkDecimal(dnppertoesq.value) > 1) {
        valido = false;
      }
    }

    var dnpdir = document.getElementById("prescricao_olho_direito_dnp");
    if (dnpdir) {
      if ((dnpdir.value > dnpdir.max || dnpdir.value < dnpdir.min) && dnpdir.value !== "") {
        valido = false;
      }

      if (this.checkDecimal(dnpdir.value) > 1) {
        valido = false;
      }
    }

    var dnpesq = document.getElementById("prescricao_olho_esquerdo_dnp");
    if (dnpesq) {
      if ((dnpesq.value > dnpesq.max || dnpesq.value < dnpesq.min) && dnpesq.value !== "") {
        valido = false;
      }

      if (this.checkDecimal(dnpesq.value) > 1) {
        valido = false;
      }
    }

    var altdir = document.getElementById("prescricao_olho_direito_altura");
    if (altdir) {
      if ((altdir.value > altdir.max || altdir.value < altdir.min) && altdir.value !== "") {
        valido = false;
      }

      if (this.checkDecimal(altdir.value) > 1) {
        valido = false;
      }
    }

    var altesq = document.getElementById("prescricao_olho_esquerdo_altura");
    if (altesq) {
      if ((altesq.value > altesq.max || altesq.value < altesq.min) && altesq.value !== "") {
        valido = false;
      }
      if (this.checkDecimal(altesq.value) > 1) {
        valido = false;
      }
    }

    if (!this.state.prescricao_done) {
      valido = false;
    }

    if (valido) {
      this.setState({ stage: stage, direction: direction }, this.scrollTop());
    }

    return valido;
  }

  changeStage = (stage, direction) => {
    if (stage === 2) {
      if (this.state.stage < stage) {
        this.validationStage1(stage, direction);
      } else {
        this.setState({ stage: stage, direction: direction }, this.scrollTop());
      }
    } else if (stage === 3) {
      if (this.state.stage < stage) {
        if (this.validationStage1()) {
          this.validationStage2(stage, direction);
        }
      } else {
        this.setState({ stage: stage, direction: direction }, this.scrollTop());
      }
    } else if (stage === 4) {
      if (this.state.stage < stage) {
        if (this.validationStage1()) {
          if (this.validationStage2()) {
            this.validationStage3(stage, direction);
          }
        }
      } else {
        this.setState({ stage: stage, direction: direction }, this.scrollTop());
      }
    } else if (stage === 5) {
      this.context.showLoading("Validando pedido...");
      var body = this.obterBody();
      var requestOptions = {};
      let cnpj = this.state.dados_empresa_faturamento_cnpj
        ? this.state.dados_empresa_faturamento_cnpj
            .replace(/[^0-9]/g, "")
            .replace("/", "")
            .replace("-", "")
        : "";
      if ((this.state.redeOticas && this.context.userSession.redeOticas) || this.context.userSession.papelCategoria === "ESSILOR") {
        requestOptions = {
          method: "POST",
          headers: {
            access_token: localStorage.getItem("token"),
            laboratorio: this.state.laboratorio,
            otica: cnpj,
          },
          body: JSON.stringify(body),
        };
      } else {
        //acrescentado o cnpj da otica no dia 02/09/2022, remover caso seja solicitado ou ter bugs em outras requisições.
        requestOptions = {
          method: "POST",
          headers: {
            access_token: localStorage.getItem("token"),
            laboratorio: this.state.laboratorio,
          },
          body: JSON.stringify(body),
        };
      }

      fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/orcamentos", requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then(json => {
          document.getElementById("main-body").style.pointerEvents = "";

          if (json) {
            var date1 = moment(json.dataEntregaPrevista).format("DD/MM/YYYY");
            var value = "R$ " + parseFloat(json.valorLiquido).toFixed(2).replace(".", ",");

            if (json.valorLiquido != undefined) {
              this.setState(
                {
                  stage: stage,
                  direction: direction,
                  json: body,
                  prazo: date1,
                  valorLiquido: value,
                  valor_flag: true,
                },
                () => {
                  // this.setState({
                  //   costumer: this.obterDadosCustomer(),
                  //   salesOrder: this.obterBodyOrderSalesForce(),
                  // });
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                },
              );
            } else {
              this.setState(
                {
                  stage: stage,
                  direction: direction,
                  json: body,
                  prazo: date1,
                  valorLiquido: "sem valor",
                  valor_flag: false,
                },
                () => {
                  // this.setState({
                  //   costumer: this.obterDadosCustomer(),
                  //   salesOrder: this.obterBodyOrderSalesForce(),
                  // });
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                },
              );
            }
          }
          this.context.hideLoading();
        })
        .catch(error => {
          if (error.status) {
            // erros 50x e 4xx com resposta do servidor
            if (error.status >= 400 && error.status <= 499) {
              this.context.hideLoading();
              return error.json().then(json => {
                Swal.fire({
                  html: json[0].mensagem + 
                  '<br><br>Em caso de dúvidas, entre em contato com o laboratório.',
                  icon: "warning",
                  title: "Erro ao criar orçamento!",
                  width: "600px",
                  confirmButtonColor: "#00518b",
                  customClass: {
                    confirmButton: "x-follow-order__filter-btn",
                  },
                }).then(result => {});
              });
            } else if (error.status === 504) {
              this.context.hideLoading();
              Swal.fire({
                html: "A requisição atingiu o tempo máximo de processamento. Tente novamente mais tarde.<br><br>Em caso de dúvidas, entre em contato com o laboratório.",
                icon: "warning",
                title: "Aviso",
                width: "600px",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              }).then(result => {});
            } else {
              this.context.hideLoading();
              Swal.fire({
                html: "Tivemos uma falha momentânea de comunicação com o laboratório.<br><br>Em caso de dúvidas, entre em contato com o laboratório.",
                icon: "warning",
                title: "Erro interno",
                width: "600px",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              }).then(result => {});
            }
          } else {
            // erros 50x sem resposta do servidor
            this.context.hideLoading();
            Swal.fire({
              html: "Tivemos uma falha momentânea de comunicação com o laboratório.<br><br>Em caso de dúvidas, entre em contato com o laboratório.",
              icon: "warning",
              title: "Erro interno",
              width: "600px",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            }).then(result => {});
          }
        });
    } else {
      this.setState({ stage: stage, direction: direction }, () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    }
  };

  handleChange = (obj, callback) => {
    if (callback) {
      this.setState(obj, callback);
    } else {
      this.setState(obj);
    }

    if (obj?.pedidoColorirNaOtica && this.state.promo_code && this.state.colorirNaOtica == true) {
      let valor = false;
      if (this.state.pedidoColorirNaOtica == true) {
        valor = false;
        this.setState({
          cores: this.state.cores_static,
          cor: this.state.cor_static,
          cor_cod: this.state.cor_cod_static,
          cor_obrigatoria: this.state.cor_obrigatoria_static,
          cor_value: this.state.cor_value_static,
          lente_coloracao: this.state.lente_coloracao_static,
          lente_coloracao_cod: this.state.lente_coloracao_cod_static,
          lente_coloracao_value: this.state.lente_coloracao_value_static,
          pedidoColorirNaOtica: valor,
          colValue: this.state.colValue_static,
          pedidoColorirNaOtica_static: true,
        });
      } else {
        valor = true;
        this.setState({
          cores_static: this.state.cores,
          cor_static: this.state.cor,
          cor_cod_static: this.state.cor_cod,
          cor_obrigatoria_static: this.state.cor_obrigatoria,
          cor_value_static: this.state.cor_value,
          lente_coloracao_static: this.state.lente_coloracao,
          lente_coloracao_cod_static: this.state.lente_coloracao_cod,
          lente_coloracao_value_static: this.state.lente_coloracao_value,
          colValue_static: this.state.colValue,
          pedidoColorirNaOtica_static: this.state.pedidoColorirNaOtica,

          pedidoColorirNaOtica: valor,
          cores: [],
          cor: "",
          cor_cod: "",
          cor_obrigatoria: false,
          cor_value: "",
          lente_coloracao: "",
          lente_coloracao_cod: "",
          lente_coloracao_value: "",
          colValue: [],
        });
      }
    }
  };

  render() {
    if (this.state.returnToChoices) {
      Alert.fire({
        text: "O serviço está temporariamente indisponível para este laboratório.",
        width: "auto",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
      return <Redirect to="/web-ordering" />;
    }

    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    if (this.state.redirectL) {
      return <Redirect to="/" />;
    }
    if (this.state.redirectWO) {
      return <Redirect to="/web-ordering" />;
    }

    let confirmOrder;

    if (this.state.stage === 5) {
      confirmOrder = <br />;
    } else {
      confirmOrder = <OrderStage onChange={this.changeStage} stage={this.state.stage} />;
    }
    return (
      <div className="x-body" id="main-body">
        {/* <Prompt
					when={this.state.inProgress}
					message={location =>
					'Deseja realmente sair desta página? Todos os dados do seu pedido serão perdidos.'
					}
				/> */}
        <main className={"x-web-ordering-pedido__main"}>
          <div className="x-web-ordering-pedido">
            <div className="x-web-ordering-pedido__container js--pedido-container">
              <section className="x-web-ordering-pedido-menu-container">
                <div className="x-web-ordering-pedido__arrow">
                  <Back />
                </div>
                {confirmOrder}
                <span className="x-web-ordering-pedido-menu__shape"></span>
              </section>
              <section className="x-web-ordering-pedido__solicitation">
                <form className="x-web-ordering-pedido__form slider-single js--formSlides" action="#">
                  {this.state.stage === 1 && (
                    <OrderDetail
                      onNext={this.changeStage}
                      order={this.state}
                      crm_enable={this.state.crm_enable}
                      handleOrderChange={this.handleChange}
                      laboratorio={this.props.location && this.props.location.state ? this.props.location.state.lab : this.state.laboratorio}
                    />
                  )}
                  {this.state.stage === 2 && <OrderLens onNext={this.changeStage} order={this.state} handleOrderChange={this.handleChange} />}
                  {this.state.stage === 3 && <OrderPerscription onNext={this.changeStage} order={this.state} handleOrderChange={this.handleChange} />}
                  {this.state.stage === 4 && <OrderFrame onNext={this.changeStage} order={this.state} handleOrderChange={this.handleChange} />}
                  {this.state.stage === 5 && <OrderConfirm onNext={this.changeStage} order={this.state} handleOrderChange={this.handleChange} />}
                </form>
              </section>
            </div>
            <OrderSummary order={this.state} />
          </div>
        </main>
      </div>
    );
  }
}

export default WOOrder;
